import React from 'react'
import './dropdown-content.css';


interface Props {
    heading: string,
    body: string,
    icon: JSX.Element,
    onClick: any,
}

const DropdownContent = (props: Props) => {
    return (
        <div className="dbullet" onClick={() => props.onClick()}>
            <div>
                {props.icon}
            </div>

            <div className="dtext-area">
                <div className="dbheading">
                    {props.heading}
                </div>
                {props.body &&
                <div className="dbbody">
                    {props.body}
                </div>
                }

            </div>
        </div>
    )
}

export default DropdownContent
