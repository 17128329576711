import React from 'react'
import './css/legals.css'


function TandC(props) {

    return (
        <div className="container">
            <div style={{ height: 40 }}/>

            <div>
                <h5>
                YOU MUST READ, AGREE WITH, AND ACCEPT ALL OF THE TERMS AND CONDITIONS CONTAINED IN THESE TERMS BEFORE BROWSING OUR PLATFORM OR USING OUR SERVICES.
                </h5>
                <br />
                <h5>
                    INTRODUCTION
                </h5>
                <p>
                Welcome to Callphone Limited! By using our platform and/ or using the services that are provided, you acknowledge that you have read, understood, and agree to be bound by our Terms and Conditions. These Terms and Conditions unconditionally extend and apply to all related applicants, internet service, or platform extensions. If you are not in agreement with all these Terms and Conditions, you are prohibited from using this Platform, and you may discontinue use immediately. Callphone Limited recommends that you save or print a copy of these Terms and Conditions for future reference.
                </p>
                <br/>
                <h5>
                    AGREEMENT TO TERMS AND CONDITIONS
                </h5>
                <p>
                Callphone Limited Terms and Conditions (these “Terms” or these Terms and Conditions”) contained in this Agreement  shall govern your use of this Platform and all its content (collectively referred to herein as this “Platform”). These Terms outline the rules and regulations guiding the use of Callphone Limited located at <a href='https://www.airvend.ng' style={{textDecoration:"underline", color: "blue"}}>https://www.airvend.ng</a> All material/ information/ documents/ services or all other entities (collectively referred to as content) that appear on the Callphone Limited shall be administered subject to these Terms and Conditions. These Terms and Conditions apply in full force and effect to your use of this Platform, and the use of this Platform, and the use of this Platform constitutes an express agreement with all the terms and conditions contained herein in full. Do not continue to use this Website if you have any objection to any of the Terms and Conditions stated on this page.
                </p>
                <br/>
                <h5>
                    WHAT’S IN THESE TERMS?
                </h5>
                <p>
                    These terms tell you the rules for using our platform: <a href="https://www.airvend.ng"  style={{textDecoration:"underline", color: "blue"}}>https://www.airvend.ng</a> and other platforms operated by Callphone Limited.
                </p>
                <h5>
                    WHO WE ARE
                </h5>
                <p>
                    <a href="https://www.airvend.ng"  style={{textDecoration:"underline", color: "blue"}}>https://www.airvend.ng</a> is a platform operated by Callphone Limited. We are a bills payment platform that makes it easier for users to make payment to their biller and accept payments from their customers.
                    References in this agreement to "Callphone", "we", "our" or "us", are to Callphone Limited depending on the context, and references to "you" or "your" are to the persons with whom Callphone enters into this agreement. Callphone offers the Services to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
                </p>
                <p>
                    These Terms of Service apply to all users of the Platform including, without limitation, users who are browsers, vendors, customers and contributors of content. By visiting our Platform, you agree to be bound by the following terms and conditions (“Terms”) including the additional terms and conditions and policies referenced herein. By agreeing to these Terms, you represent that you are at least of the age of consent in Nigeria. If you do not agree to all the Terms then you may not access the Platform or make use of any of our Services.
                </p>
                <h5>
                    DEFINITIONS/TERMINOLOGY
                </h5>
                <p>
                The following definitions apply to these Terms and Conditions, Privacy Statement, Disclaimer Notice and all Agreement: “User”, “Visitor”, “Clients,” “Customer,” “You” and “Your” refers to you, the person(s) that use this Website. “Callphone Limited”, “We”, “Our” and “Us”, refers to our Platform/Company. “Party,” “Parties”, or “Us,” refers to both you and us. All terms refer to all considerations of Callphone Limited necessary to undertake support to you for the express purpose of meeting your User needs in respect of our services, under and subject to, prevailing law of the country in which (Airvend) operates (F.C.T, Abuja). Any use of these definitions or other glossaries in the singular, plural, capitalization, and/or pronoun are interchangeable but refer to the same.
                </p>
                <p>
                In these terms, the following definitions shall apply:
                </p>
                <p>
                    <strong>“Content"</strong> means any content, writing, images, audiovisual content or other information published on the Platform;
                </p>
                <p>
                    <strong>"Materials"</strong> means any materials, information or documentation that we may provide to you in connection with your use of the Products including documentation, data, information developed any use and other materials which may assist in your use of the Service;
                </p>
                <p>
                    <strong>"Parties"</strong> means you and Callphone;
                </p>
                <p>
                    <strong>"Products or Service"</strong> means the Platform including all pages, subpages, blogs, forums and other connected internet content whatsoever; bill payments; data vending; airtime vending; virtual dollar cards; money transfer and any other service that may be provided by Callphone;
                </p>
                <p>
                    <strong>"Platform"</strong> means the website - <a href="https://www.airvend.ng"  style={{textDecoration:"underline", color: "blue"}}>https://www.airvend.ng</a> https://www.airvend.ng and any other platform operated by Callphone;
                </p>
                <p>
                    <strong>"Third Party Goods/Services"</strong> means goods, products and services of the third party that may be advertised on the Platform; and
                </p>
                <p>
                    <strong>"URL"</strong> means Uniform Resource Locator.
                </p>

                <h5>
                    CREATING AN ACCOUNT
                </h5>
                <p>
                    To create an account users provide MSISDN and basic KYC details(name, date of birth). and creates a unique password that enables users to gain access to their accounts.
                </p>
                <h5>
                    SAFETY OF ACCOUNT DETAILS
                </h5>
                <p>
                    You may be required to register with us to have access to our Service.
                </p>
                <p>
                    You will be required to provide certain personal information, which includes but not limited to your name, user name, email address and password. The information provided must be correct and accurate.
                </p>
                <p>
                    Your account details must not be disseminated to anyone and when you discover that your information has been compromised, you agree to notify us immediately.
                </p>
                <p>
                    If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. you must not disclose it to any third party.
                </p>
                <p>
                    We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these Terms.
                </p>
                <p>
                    You also acknowledge that you are responsible for the security of your personal information and that the Callphone does not accept liability for the security of your account as you agree to be responsible for maintaining the confidentiality of your passwords or other account identifiers which you choose and all activities under your account.
                </p>
                <p>
                    Callphone reserves the right to terminate your account where you have provided false inaccurate or incorrect information.
                </p>

                <h5>
                    INTELLECTUAL PROPERTY
                </h5>
                <p>Other than the content you own and opted to include on this Platform, under these Terms, Callphone Limited and/or its licensors own and reserve all intellectual property rights of this Platform. You are granted a limited license, subjected to the restrictions entailed in these Terms and Conditions, for purposes of viewing this Platform’s contents. 
</p>
                <p>
                    We are the owners or the licensor of all intellectual property rights in our Platform, and in the material published on it. Those works are protected by intellectual property laws. All such rights are reserved.
                </p>
                <p>
                    You are granted a limited license only, subject to the restrictions provided in these Terms.
                </p>
                <p>
                    You must not use any part of the Content on our Platform for commercial purposes without obtaining a licence to do so from us or our licensors.
                </p>
                <p>
                    You agree that we retain ownership of all Content included on the Platform (text,graphics, video, software, data, page layout, images, and any other information capable of being stored in a computer) other than the contents uploaded by users.
                </p>
                <p>
                    Nothing on this Platform shall be construed as granting any license or right to use any trademark or logo displayed on the Platform without obtaining the prior written consent of Callphone.
                </p>
                <p>
                    You hereby agree not to reproduce or distribute Callphone's intellectual property or use the intellectual property for any unlawful purpose.
                </p>

                <h5>SERVICES</h5>
                <p>
                The content of this Platform is not intended for use or distribution to any person or entity in any jurisdiction, geographical location, or country/state where such use or distribution will be contrary to the laws and regulations or subject Callphone Limited to any form of regulation, claims, demands, costs, liabilities, damages, or expenses. The Platform is intended for users who are at least 18 years of age. If you are under the age of 18, you cannot use or register to use this Platform or its services without parental permission or consent. By agreeing to these Terms and Conditions, you have the necessary legal capacity to comply and be bound by these Terms and Conditions.
                </p>

                <h5>
                    PRIVACY POLICY
                </h5>
                <p>
                    We will only use your personal information as set out in our <a href="/legals/privacy" style={{textDecoration:"underline", color: "blue"}}>Privacy Policy</a>.
                </p>
            </div>

            <div style={{height: "228px"}}></div>

        </div>
    )
}


export default TandC
