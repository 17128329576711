import React from "react";
import "./apple.css";

const AppleStore = () => {
  return (
    <button
      className="apple_store_btn"
      onClick={() =>
        (window.location.href =
          "https://apps.apple.com/ng/app/airvend/id1439778926")
      }
    >
      <div>
        <svg
          width="16"
          height="20"
          viewBox="0 0 16 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.44 7.066L15.738 7.514L15.308 7.838C14.695 8.301 13.575 9.145 13.575 10.818C13.575 12.798 14.843 13.56 15.453 13.926C15.722 14.088 16 14.255 16 14.62C16 14.858 14.098 19.97 11.336 19.97C10.66 19.97 10.182 19.767 9.761 19.588C9.335 19.408 8.967 19.251 8.359 19.251C8.051 19.251 7.661 19.397 7.249 19.551C6.685 19.761 6.048 20 5.324 20H5.304C2.402 19.983 0 14.062 0 11.046C0 6.12 3.698 5.042 5.124 5.042C5.766 5.042 6.452 5.294 7.057 5.517C7.48 5.672 7.917 5.833 8.161 5.833C8.307 5.833 8.65 5.696 8.953 5.577C9.599 5.319 10.403 5 11.34 5C12.045 5 14.165 5.153 15.44 7.066ZM10.998 0C11.058 2.421 9.54 4.1 8.024 3.995C7.774 2.063 9.539 0 10.998 0Z"
            fill="white"
          />
        </svg>
      </div>
      <div>
        <div className="apple_store_btn_small_text">Get in on</div>
        <div className="apple_store_btn_large_text">Apple Store</div>
      </div>
    </button>
  );
};

export default AppleStore;
