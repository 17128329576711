import React from "react";
import Header from "../../components/referral/header/Header";
import ReferralLayout from "../../layout/ReferralLayout";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Footer from "../../components/footer/footer";

const ReferralFaq = () => {
  return (
    <>
      <div className="top-bg">
        <div className="container">
          <Header />

          <div style={{ height: 137 }} />

          <div className="faqs-head">
            <div>
              <div className="faqs-title">#FAQs</div>

              <div style={{ height: 30 }} />

              <div className="faqs-msg">
                Learn answers to frequently asked questions about Referrals on
                Airvend
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div style={{ height: 40 }} />

        {/* Faqs Start */}

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="acc-heading">
              How do referrals work?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography className="accordion-body">
                When your friend registers an account with your referral code,
                and purchases a value-added service (Airtime, Data, CableTV, or
                Electricity bills) with a minimum of ₦1000, you will get a ₦100
                cashback bonus in your incentive wallet.
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>

        <div style={{ height: "14px" }}></div>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="acc-heading">
              Who is a valid user?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography className="accordion-body">
                A valid user/invitee is one who is unregistered with Airvend at
                the time of the referral.
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>

        <div style={{ height: "14px" }}></div>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="acc-heading">Available Platform</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="accordion-body">
              Please note that this feature is currently available only on our
              mobile app.
            </Typography>
          </AccordionDetails>
          <AccordionDetails>
            <Typography className="accordion-body">
              Please be sure your friend downloads the mobile app on their
              device, and signs-up using your referral code.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <div style={{ height: "14px" }}></div>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="acc-heading">About Cashback</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="accordion-body">
              The cashback bonus in your incentive wallet can be used to pay for
              only VAS (Airtime, Data, CableTV, or Electricity bills).
            </Typography>
          </AccordionDetails>
        </Accordion>

        <div style={{ height: "14px" }}></div>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="acc-heading">Other</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="accordion-body">
              🔵 A new user can only bind the inviter once, and a device, and
              BVN can only participate once.
            </Typography>
          </AccordionDetails>
          <AccordionDetails>
            <Typography className="accordion-body">
              🔵 Airvend reserves the right to suspend the award on suspicious
              invitation activities.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <div style={{ height: "14px" }}></div>

        {/* Faqs End */}

        <div style={{ height: "24px" }}></div>

        <Footer removeAbsolue={true} />
      </div>
    </>
  );
};

export default ReferralFaq;
