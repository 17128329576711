import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import "./css/home-page.css";

import Button from "../../components/button/button";
import DropdownContent from "../../components/dropdown-content/dropdown-content";

import Testimonial from "../../components/testimonial/testimonial";
import { useEffect } from "react";
// import Nav from './nav';
import Nav from "../../components/nav/nav";
import Footer from "../../components/footer/footer";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
}));

function HomePage(props) {
  const [index, setIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [showMenus, setShowMenus] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((count) => ++count);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`box-management ${count % 2 ? "box-bg1" : "box-bg2"}`}>
      {/* carousel */}
      <div
        className={`carousel-box move-right-left ${
          count % 2 ? "display-block" : "display-none"
        }`}
      >
        <img
          src={require("../../components/asset/____.svg").default}
          style={{ maxWidth: "100%" }}
        />
      </div>

      <div
        className={`carousel-box move-left-right ${
          count % 2 ? "display-none" : "display-block"
        }`}
      >
        <img
          src={require("../../components/asset/1____.svg").default}
          style={{ maxWidth: "100%" }}
        />
      </div>
      {/* carousel */}

      {/* pseudo body */}
      <div className="absolute-div">
        {/* <div style={{ height: 54 }} className="shrink-height"/> */}

        <div className="container body-flow">
          <Nav />

          <div style={{ height: 189 }} />

          <div className="central-msg">#SeamlessPayments</div>

          <div style={{ height: 30 }} />

          <div className="msg">
            A seamless bill payment service for individuals and businesses.
          </div>

          <div className="app-nav">
            <div
              className="app-nav-icon"
              onClick={() =>
                (window.location.href =
                  "https://play.google.com/store/apps/details?id=com.callphoneng.airvend")
              }
            >
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.645 11L14 13.445L3 20L11.645 11ZM0.857 0L11 9.998L0.857 20C0.344 19.734 0 19.25 0 18.62V1.38C0 0.75 0.344 0.265 0.857 0ZM15.361 7L17.477 8.321C18.167 8.875 18.167 10.123 17.52 10.677L15.361 12L13 9.5L15.361 7ZM3 0L14 6.555L11.645 9L3 0Z"
                  fill="white"
                />
              </svg>
              <div className="text-area">
                <div className="top">Get it on</div>
                <div className="bottom">Google Play</div>
              </div>
            </div>

            <div style={{ width: 30 }} />

            <div
              className="app-nav-icon"
              onClick={() =>
                (window.location.href =
                  "https://apps.apple.com/ng/app/airvend/id1439778926")
              }
            >
              <svg
                width="16"
                height="20"
                viewBox="0 0 16 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.44 7.066L15.738 7.514L15.308 7.838C14.695 8.301 13.575 9.145 13.575 10.818C13.575 12.798 14.843 13.56 15.453 13.926C15.722 14.088 16 14.255 16 14.62C16 14.858 14.098 19.97 11.336 19.97C10.66 19.97 10.182 19.767 9.761 19.588C9.335 19.408 8.967 19.251 8.359 19.251C8.051 19.251 7.661 19.397 7.249 19.551C6.685 19.761 6.048 20 5.324 20H5.304C2.402 19.983 0 14.062 0 11.046C0 6.12 3.698 5.042 5.124 5.042C5.766 5.042 6.452 5.294 7.057 5.517C7.48 5.672 7.917 5.833 8.161 5.833C8.307 5.833 8.65 5.696 8.953 5.577C9.599 5.319 10.403 5 11.34 5C12.045 5 14.165 5.153 15.44 7.066ZM10.998 0C11.058 2.421 9.54 4.1 8.024 3.995C7.774 2.063 9.539 0 10.998 0Z"
                  fill="white"
                />
              </svg>
              <div className="text-area">
                <div className="top">Download on the</div>
                <div className="bottom">App Store</div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
      {/* pseudo body */}
    </div>
  );
}

export default HomePage;
