import React, { useState } from 'react'
// import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';


/* import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'; */

import './css/legals.css';


//import Testimonial from '../../components/testimonial/testimonial'
import Nav from '../../components/nav/nav';
import Footer from '../../components/footer/footer';
import SettingsNav from '../../components/setting-nav/setting-nav';
import Privacy from './privacy';
import TandC from './tandc';
import { useParams } from 'react-router';

/* 
const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
    }
  })); */

  const settingNavContent = [
    {
        "state": "settings-active",
        "name": "Terms and Conditions",
        "path": "/settings-Profile Details"
    },
    {
        "state": "settings-inactive",
        "name": "Privacy Policy",
        "path": "/settings-Business Details"
    }
]



function Legals(props) {
    const [settingPage, setsettingPage] = useState(() => 0)
    const { page } = useParams<{ page: string }>();

    useEffect(() => {
        if(page){
            setsettingPage(page === "terms" ? 0 : 1)
            if(page!=="terms"){
                settingNavContent[0]["state"] = "settings-inactive"
                settingNavContent[1]["state"] = "settings-active"
            }
        }else{
            setsettingPage(0)
        }
    }, [page])
    return (
        <div className="box-management">

            <div className="top-bg">
                <div className="container">
                    <Nav />
                    <div style={{ height: 137 }}/>

                    <div className="faqs-head">
                        <div>
                            <div className="faqs-title">
                                #LegalNotes
                            </div>

                            <div style={{ height: 30 }}/>

                            <div className="faqs-msg">
                                Last updated October 14, 2022
                            </div>
                        </div>

                        <div className="faqs-hand">
                            <img src={require('../../components/asset/legals-hand.svg').default} alt="" style={{maxWidth:625, marginRight: -100, marginTop:-100}}/>
                        </div>
                    </div>
                </div>

            </div>

            <div className="container body-flow">


                <div style={{ height: 20 }}/>



                {/* tandC and privacy Start */}


                <SettingsNav navContent={settingNavContent} handleClick={index => {
                    console.log(index)
                    setsettingPage(prev => index)
                }} />

                <div className="settings-pseudo-body">
                    {<>
                        {settingPage === 0 &&
                            <TandC />
                        }
                        {settingPage === 1 &&
                            <Privacy />
                        }

                        </>
                    }
                </div>


                {/* tandC and privacy End */}

                <div style={{height: "54px"}}></div>






                <Footer removeAbsolue={true}/>

            </div>


        </div>
    );
}

export default Legals
