import React from 'react'
import './css/legals.css'


function Privacy(props) {

    return (
        <div className="container">
            <div style={{ height: 40 }}/>

            <div>
                <h5>
                    INTRODUCTION
                </h5>
                <p>
                    This website, <a href="https://www.airvend.ng" style={{textDecoration:"underline", color: "blue"}}>https://www.airvend.ng</a>  (“Platform”), is operated by Callphone Limited (hereinafter referred to as “we”, “our”, “us” or “Callphone”).
                    <br />
                    We are committed to the protection of all personal information that you may provide to us. This privacy policy (“Policy”), together with our <a href="https://airvend.ng/legals/privacy" style={{textDecoration:"underline", color: "blue"}}>terms and conditions</a> and any other documents referred to on it, sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.
                    <br />
                    By visiting and accessing our Platform, you are accepting and consenting to the terms and conditions in this Policy. If you do not agree to this Policy, please do not access our Platform.
                </p>

                <p>
                By using this Platform and its services, you may provide us with certain personal information. By using Callphone Limited or its services, you authorize us to use your information in any country or state that we operate in. We reserve the right to use such information to improve your user experience and facilitate mailing and traffic, and market analytics.
                </p>

                <p>
                By accessing this Platform, specific information about the user, such as internet protocol (IP) addresses, site navigation, user software, and the surfing time, along with other similar information, will be stored on Callphone Limited servers. Information about their identities, such as name, address, contact details, billing information, and other information stored on this Platform, will strictly be used only for statistical purposes and will not be published for general access. Callphone Limited, however, assumes no responsibility for the security of this information.
                </p>

                <h5>
                    THE INFORMATION THAT WE COLLECT FROM YOU
                </h5>
                <p>
                We may receive, use, store and transfer different kinds of personal data about you including the following:
                <br />
                <br />
                <strong>Identity Data:</strong> includes [first name, last name, maiden name, username or similar identifier, marital status, title, date of birth, gender].
                <br />
                <br />
                <strong>Contact Data:</strong> includes [billing address, delivery address, email address and telephone numbers].
                <br />
                <br />
                <strong>Financial Data:</strong> includes [bank account and payment card details].
                <br />
                <br />
                <strong>Transaction Data:</strong> includes [details about your transactions].
                <br />
                <br />
                <strong>Device Data:</strong> includes [information on the devices with which you access the platform].
                <br />
                <br />
                <strong>Profile Data:</strong> includes [your username and password, picture, transaction history, your interests, preferences, feedback and survey responses].
                <br />
                <br />
                <strong>Usage Data:</strong> includes [details of your use of our services ].
                <br />
                <br />
                <strong>Marketing and Communications Data:</strong> includes [your preferences in receiving marketing from us and our third parties and your communication preferences].
                <br />
                <br />
                <strong>Location Data:</strong> includes [your current location disclosed by GPS technology or any other location territory].
                <br />
                <br />

                We may also collect, use and share aggregated data such as statistical or demographic data for any purpose. Aggregated data could be derived from your personal data but is not considered personal data as this data will not directly or indirectly reveal your identity. For example, we may aggregate your usage data to calculate the percentage of users accessing a specific Platform feature.

                </p>
                <h5>
                    INFORMATION FROM OTHER SOURCES
                </h5>
                <p>
                    <br />
                    This is information we receive about you if you use any of the other Platforms we operate or the other services we provide.
                    <br />
                    <br />

                    When you create an account with us, we may collect information, including nonpublic personal information, about you from third party service providers in order to verify your identity and for fraud prevention, including your prior addresses and names.
                </p>
                <h5>
                    COOKIES
                </h5>
                <p>
                Callphone Limited employs the use of cookies. By accessing Our Website, you agree to use cookies in agreement with our Cookie Policy. Our interactive Website’s optimal functionality uses cookies to retrieve the user’s information for each visit. Some of our affiliate partners may also use cookies.
                    <br />
                    <br />
                    You can set your browser to refuse all or some browser cookies or to alert you when Platforms set or access cookies. If you disable or refuse cookies, please note that some parts of this Platform may become inaccessible or not function properly.
                </p>

                <h5>
                    ACCEPTABLE USE
                </h5>
                <p>
                You may use this Platform as permitted by these Terms and Conditions and may not use this Platform for any purpose other than for which Callphone Limited makes the Platform and its services available.
                </p>
                <p>
                    We may provide you with other items in connection with your use of this Platform.
                </p>
                <p>
                    We hereby grant you the license to use our Platform for your personal, non-commercial use to retrieve, display and view the Content on a computer screen.
                </p>
                <p>
                    The license created under these Terms is limited, non-exclusive, non-transferable and revocable.
                </p>
                <p>
                    You agree that you will not use the Contents or Materials for any other purpose which may be contrary to your license to use this Service.
                </p>
                <p>
                    Any unauthorized use by you shall terminate the permission or license granted by this Platform.
                </p>

                <h5>
                    PROHIBITED USE
                </h5>
                <p>
                    You agree not to use the Service in the following manner:
                </p>
                <ul>
                    <li>
                        <p>
                            to harass, abuse or threaten others or otherwise violate any person's legal rights;
                        </p>
                    </li>
                    <li>
                        <p>
                            to perpetrate fraud;
                        </p>
                    </li>
                    <li>
                        <p>
                            to create or transmit unnecessary spam to any person or URL;
                        </p>
                    </li>
                    <li>
                        <p>
                            to post, transmit or cause to be posted or transmitted, any communication or solicitation designed to obtain password, account, or private information of other users or persons;
                        </p>
                    </li>
                    <li>
                        <p>
                            to post copyrighted content which does not belong to you and without obtaining the prior consent of the author;
                        </p>
                    </li>
                    <li>
                        <p>
                            to use robot, spider, scraper or other automated means to access this Service without obtaining the prior consent of the Callphone;
                        </p>
                    </li>
                    <li>
                        <p>
                            to engage in or create any unlawful gambling, sweepstakes, or scheme;
                        </p>
                    </li>
                    <li>
                        <p>
                            publishing or distributing any obscene or defamatory material;
                        </p>
                    </li>
                    <li>
                        <p>
                            using this Service in any way that impacts user access to the Platform ;
                        </p>
                    </li>
                    <li>
                        <p>
                            to engage in advertisement or solicit any User to buy or sell products or services without obtaining the prior consent of the Callphone;
                        </p>
                    </li>
                    <li>
                        <p>
                            disseminating computer viruses or other software;
                        </p>
                    </li>
                    <li>
                        <p>
                            violating any intellectual property rights of the Callphone or any third party;
                        </p>
                    </li>
                    <li>
                        <p>
                            to use the Platform or any of the Services for illegal spam activities.
                        </p>
                    </li>
                </ul>

                <h5>
                    ADDITIONALLY, YOU AGREE THAT YOU WILL NOT dO AS FOLLOWS:
                </h5>
                <ul>
                    <li>
                        <p>
                            interfere or attempt to interfere with the proper working of this Platform; or
                        </p>
                    </li>
                    <li>
                        <p>
                            bypass any measures we may use to prevent or restrict access to this Platform ;
                        </p>
                    </li>
                    <li>
                        <p>
                            to interfere with or circumvent the security features of this Service;
                        </p>
                    </li>
                    <li>
                        <p>
                            to damage, disable, overburden or impair this Service or any other person's use of this Service.
                        </p>
                    </li>
                    <li>
                        <p>
                            to use this Service contrary to the applicable laws and regulations or in a way that causes, or may cause harm to this Platform, any person or business entity. We reserve the right to terminate your use of the Service for violating any of the prohibited uses.
                        </p>
                    </li>
                    <li>
                        <p>
                            to reverse engineer or permit the reverse engineering or disassemble any code or software from or on the Platform or Services.
                        </p>
                    </li>
                    <li>
                        <p>
                            violate the security of the Platform or other Services through any unauthorized access, circumvention of encryption or other security tools, data mining or interference with any host or user or network.
                        </p>
                    </li>
                </ul>

                <h5>
                    THE PURPOSE FOR WHICH WE WILL USE YOUR DATA
                </h5>
                <p>
                    <strong>The information that we collect from you -</strong> We may use information held about you in the following ways:
                </p>
                <ul>
                    <li>
                        <p>
                            to carry out our obligations arising from any contracts entered into between you and us and to provide you with the information, products and services that you request from us;
                        </p>
                    </li>
                    <li>
                        <p>
                            to provide you with information about other goods and services we offer that are similar to those that you have already purchased or enquired about;
                        </p>
                    </li>
                    <li>
                        <p>
                            to provide you, or permit selected third parties to provide you, with information about goods or services we feel may interest you. If you are an existing customer, we will only contact you by electronic means (e-mail or SMS) with information about goods and services similar to those which were the subject of a previous sale or negotiations of a sale to you. If you are a new customer, and where we permit selected third parties to use your data, we (or they) will contact you by electronic means only if you have consented to this. If you do not want us to use your data in this way, or to pass your details on to third parties for marketing purposes, please tick the relevant box situated on the form on which we collect your data;
                        </p>
                    </li>
                    <li>
                        <p>
                            to ensure that content from our site is presented in the most effective manner for you and to notify you about changes to our service;
                        </p>
                    </li>
                    <li>
                        <p>
                            to administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;
                        </p>
                    </li>
                    <li>
                        <p>
                            to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you; and
                        </p>
                    </li>
                    <li>
                        <p>
                            to make suggestions and recommendations to you and other users of our site about goods or services that may interest you or them.
                        </p>
                    </li>
                </ul>
                <p>
                    <strong>Information we receive from other sources -</strong> We will combine this information with information you give to us and information we collect about you. We will use this information and the combined information for the purposes set out above (depending on the types of information we receive).
                </p>

                <h5>
                    DISCLOSURE OF YOUR PERSONAL INFORMATION
                </h5>
                <p>
                    You agree that we have the right to share your personal information:
                </p>
                <ul>
                    <li>
                        <p>
                            with any of our affiliated companies and service providers;
                        </p>
                    </li>
                    <li>
                        <p>
                            with financial service providers, including the financial institutions identified in your cardholder bank agreement that provide banking services in connection with your account;
                        </p>
                    </li>
                    <li>
                        <p>
                            with another user, when you sign up for our services via a referral link. The user that referred you may receive information indicating that you have enrolled with us;
                        </p>
                    </li>
                    <li>
                        <p>
                            with non-financial companies, such as email service providers that perform marketing services on our behalf, and fraud prevention service providers that use the information to provide services to us;
                        </p>
                    </li>
                    <li>
                        <p>
                            with a non-affiliated third-party to access and transmit your personal and financial information from a relevant financial institution. You grant the third-party the right, power, and authority to access and transmit this information according to terms of their privacy policy;
                        </p>
                    </li>
                    <li>
                        <p>
                            with selected third parties including business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you;
                        </p>
                    </li>
                    <li>
                        <p>
                            with third parties to whom we may choose to sell, transfer or merge parts of our business or our assets or alternatively, acquire all or parts of their businesses;
                        </p>
                    </li>
                    <li>
                        <p>
                            in response to a request for information, if we are required by, or we believe disclosure is in accordance with, any applicable law, regulation or legal process; and
                        </p>
                    </li>
                    <li>
                        <p>
                            with relevant law enforcement officials or other third parties, such as investigators or auditors, if we believe it is appropriate to investigate fraud.
                        </p>
                    </li>

                </ul>

                <h5>
                    TRANSFER TO THIRD PARTIES
                </h5>
                <p>
                    We may provide other users with your personal information to complete a transaction. Such information may include your name, account identity, contact details or such additional information required information for the transaction.
                </p>

                <h5>
                    LINKS TO THIRD PARTY CONTENT
                </h5>
                <p>
                This Platform may contain links to Platforms or applications operated by third parties. Please know that we do not control any such third party platforms or applications or the third party operator. Callphone Limited is not responsible for and does not endorse any third party platforms or applications or their availability or content.
Callphone Limited accepts no responsibility for adverts contained within the Platform. You agree that you do so at your own risk when you purchase any goods and/or services from any such third party. The advertiser, and not us, remains responsible for such goods and/or services, and if you have any questions or complaints about them, you should contact the advertiser.
                </p>
                <p>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party platform. Please review the third-party’s policies and practices carefully and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>

                <h5>USER CONTENT</h5>
                <p>In these Platform Standard Terms and Conditions, “Use Content” shall mean any audio, video, text, images, or other material or content you choose to display on this Platform. Concerning user content, by displaying it, you grant Callphone Limited a non-exclusive, worldwide, irrevocable, royalty free, sub licensable license to use, reproduce, adapt, publish, translate and distribute it on any media.</p>

                <p>User Content must be your own and must not be infringing on any third party’s rights. Callphone Limited reserves the right to remove any of your content from this Platform at any time, without notice.</p>

                <p>Callphone Limited is permitted to monitor your activities on the Platform and remove any user content considered inappropriate, offensive, contrary to applicable laws and regulations, or causes a breach of these Terms and Conditions.</p>

                <p>You warrant and represent that:</p>
                <ol>
                    <li>You are entitled to upload/input/publicize content on our Platform and have the necessary legal capacity, license or consent to do so;</li>

                    <li>Your content does not infringe any intellectual property right, including without limitation to copyright, patent, or trademark of any third party;</li>
                    <li>Your content is true, accurate, current, complete, and relate to you and not a third party;</li>
                    <li>Your content does not contain any libelous, defamatory, offensive, immoral, or otherwise illegal material which is an invasion of privacy; and </li>
                    <li>The content will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                </ol>

                <p>You must grant Callphone Limited a non-exclusive license to use, reproduce, edit and authorize others approved by us to use, reproduce and edit any of your content in any form, format, or media.</p>

                <h5>ELECTRONIC COMMUNICATIONS</h5>
                <p>You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications we provide to you electronically, via email and on this Platform, satisfy any legal requirements that communications must be in writing.</p>

                <h5>AFFILIATE MARKETING AND ADVERTISEMENT</h5>
                <p>We may engage in affiliate marketing and advertisement whereby we receive a commission on the sale of Third-Party Goods and/or Services through our Service. We may also accept advertising and sponsorship from commercial businesses or receive other forms of advertising compensation.</p>


                
                <h5>
                    CHANGES TO PERSONAL INFORMATION
                </h5>
                <p>
                    It is imperative that your data with us is current and accurate. You may request for the rectification of any personal data that we hold about you so as to enable you to correct any incomplete or inaccurate data we hold about you. We may, however, need to verify the accuracy of the new data you provide to us.
                </p>

                <h5>
                    DATA SECURITY AND RETENTION
                </h5>
                <p>
                    We deploy strict physical, electronic, and administrative security measures to protect your information from access by unauthorised persons, against unlawful processing and foreseeable hazards and breaches when online.
                </p>
                <p>
                    We will retain your data for as long as necessary for the said purpose of the processing, and after that, we will keep your data as long as the law requires.
                </p>

                <h5>
                    YOUR LEGAL RIGHTS
                </h5>
                <p>
                    You have the right to:
                </p>
                <ul>
                    <li>
                        <p>
                            <strong>Request access</strong> to your personal data (commonly known as a “data subject access request”). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Request correction</strong> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Object to processing</strong> of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Request restriction of processing</strong> of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:
                        </p>
                    </li>
                    <div style={{paddingLeft: 40}}>
                        <li>
                            <p>
                                If you want us to establish the data’s accuracy.
                            </p>
                        </li>
                        <li>
                            <p>
                                Where our use of the data is unlawful but you do not want us to erase it.
                            </p>
                        </li>
                        <li>
                            <p>
                                Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.
                            </p>
                        </li>
                        <li>
                            <p>
                                You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.
                            </p>
                        </li>
                    </div>
                    <li>
                        <p>
                            <strong>Request the transfer</strong> of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Withdraw consent at any time</strong> where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
                        </p>
                    </li>
                </ul>

                <h5>
                    SALE OF GOODS/SERVICES
                </h5>
                <p>
                    The Platform may offer Products or Services for sale. Callphone undertakes to give accurate information about the description of the Products and Services. However, Callphone does not guarantee the reliability of any information relating to the Products and Services.
                </p>
                <p>
                    We reserve the right to refuse to sell the Services provided on the Platform at our sole discretion.
                </p>
                <p>
                    Note that there is no refund/replacement policy as the Services provided are sold "As it is".
                </p>

                <h5>
                    PAYMENT AND BILLING
                </h5>
                <p>
                    If you register for our Service or to purchase any Services offered on this Platform, you agree to pay the full price for the Services when the purchase is made.
                </p>
                <p>
                    The total price will also include the taxes applicable on the date of purchase.
                </p>
                <p>
                    The total price of the Services provided including all applicable taxes is included upon the confirmation of the order.
                </p>

                <h5>
                    PERFORMANCE OF SERVICES
                </h5>
                <p>
                    Upon payment for our Services, we may offer you the opportunity to book a time and date for the performance of the Services. Services will be performed within a reasonable time.
                </p>
                <p>
                    If you have any questions regarding the time and date for the performance, contact us.
                </p>

                <h5>
                    ELECTRONIC COMMUNICATIONS
                </h5>
                <p>
                    You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications we provide to you electronically, via email and on this Platform, satisfy any legal requirements that communications must be in writing.
                </p>

                <h5>LICENSE</h5>
                <p>
                Unless otherwise stated, Callphone Limited and/or its licensors own the intellectual property rights for all content on Callphone Limited. All intellectual property rights are reserved. You may access any Platform content from Callphone Limited for your personal use subject to restrictions set in these terms and conditions.
                </p>

                <p>Callphone Limited hereby restricts you from all of the following:</p>
                <ol>
                    <li>Republishing any Callphone Limited content in any media;</li>
                    <li>Reproducing, duplicating, or copying any Callphone Limited content;</li>
                    <li>Selling, renting, sublicensing, and /or otherwise commercializing any Callphone Limited content;</li>
                    <li>Public performing and/or displaying any Callphone Limited;</li>
                    <li>Using this Platform in a manner that is,or maybe, damaging and/or impacts user access to this Platform;</li>
                    <li>Using this Platform contrary to the relevant rules, laws, and regulations of your country of residence, or in a manner that causes, or may cause, harm to the Platform, or any person or business entity;</li>
                    <li>Conducting data mining or any other similar activity concerning this Platform, or while using this Platform; and</li>
                    <li>Using this Platform to engage in any form of business advertising or marketing.</li>
                </ol>

                <p>Specific areas of the Platform may be restricted from user access, and Callphone Limited may further extend such restrictions to the entire Platform, at any time, and in its sole discretion. Any user identification, security key, or password you may have on this Website are confidential, and you are responsible for maintaining such information confidentiality.</p>

                <h5>LINKING AND HYPERLINKING RIGHTS</h5>
                <p>We reserve the right to file requests that you remove all links or any particular link created by you that redirect to our Platform, and you approve to immediately remove such links to our Platform upon request. We may amend the terms and conditions of these linking rights at any time. By continuously linking to our Platform, you agree to be bound to and follow the terms of this linking policy.</p>
                <p>Feel free to contact us if you find any link on our Platform that is offensive, and we may consider requests to remove suh links. Still, we are not obligated to do so or respond to you directly or immediately.</p>

                <h5>HYPERLINKING OF OUR CONTENT</h5>
                <p>Organizations such as search engines, government agencies, news organizations, and online directories may link to our Platform without prior written approval. We may review other link requests from popular consumer and/or information specialists, charity organizations, internet portals, educational institutions, trade associations. Any interested organization must inform and contact us for further information regarding our linking policy. However, such linking does not imply endorsement, sponsorship, partnership, or approval by us of any kind.</p>
                <p>No use of our logo or other design intellectual property will be allowed for linking, except a trademark license agreement.</p>

                <h5>CHANGE TO SERVICE</h5>
                <p>You accept that the Callphone may vary, alter, amend or update the Content or Services, at any time and without your consent.</p>

                <h5>
                    CHANGES TO THIS POLICY
                </h5>
                <p>
                    We may periodically update this Policy without notifying you, but we will display a conspicuous notice on this Platform to inform you of any significant changes to our Policy.
                    Please check back frequently to see whether there are any updates or changes to this Policy.
                </p>

                <h5>
                    NO WARRANTIES
                </h5>
                <p>
                You agree that you use this Platform solely at your risk as Callphone does not warrant the accuracy of the contents in this Platform. You assume all the risk of viewing, reading, downloading the contents of this Platform.
                </p>
                <p>Callphone expressly disclaims all express and implied warranties such as implied warranty of merchantability as Callphone makes no warranties that the Platform or other Services will be accurate, error-free, secure or uninterrupted.</p>
                <p>
                    You are responsible for configuring your information technology, computer programmes to access our Platform. You should use your own virus protection software.
                </p>

                <p>Callphone makes no warranty about the suitability, reliability, availability, timeliness and accuracy of the information, Contents, Services and other materials contained herein for any purpose. Callphone hereby disclaims all warranties and conditions with regard to the information, software, products and related graphics and materials, including all implied warranties or conditions of merchantability, fitness for a particular purpose, title and non-infringement.</p>

                <p>You also agree that the Callphone and its affiliates shall not be liable for any direct, indirect, punitive and all consequential damages or any damages whatsoever including, but not limited to damages for loss of use, data or profits, the failure to provide Services or for any information, software, Products, Services, related graphics and materials obtained through this Platform, or otherwise arising out of the use of this Platform, whether based on contract, negligence, strict liability, or otherwise.</p>

                <h5>
                    SERVICE INTERRUPTIONS
                </h5>
                <p>
                Callphone may from time to time interrupt your access or use of this Platform including to perform some maintenance or emergency services and you agree that the Callphone shall not be held liable for any damage, loss which may arise thereof.
                </p>

                <h5>
                    SUSPENSION OR WITHDRAWAL OF PLATFORM
                </h5>
                <p>
                Our Platform is made available free of charge. We do not guarantee that our Platform, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our Platform for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.
                    <br />
                    <br />
                    You are also responsible for ensuring that all persons who access our Platform through your internet connection are aware of these terms of use and other applicable terms and conditions and that they comply with them.
                </p>

                <h5>
                    TERMINATION/RESTRICTION OF ACCESS
                </h5>
                <p>The provisions of these Terms and Conditions shall remain in full force and effect while you use the Callphone Limited or its services. Users may terminate their use by following the instructions for terminating user accounts in your account settings or by contacting us at <a href="mailto:info@callphoneng.com" style={{textDecoration:"underline", color: "blue"}}>info@callphoneng.com</a></p>
            <p>We reserve the right and sole discretion to, and without notice or liability, deny access to and use of the Platform (including blocking specific IP addresses) to any user for any reason including but not limited to breach of any representation, warranty, or Agreement in these Terms or any applicable law or regulation.</p>
               <p>We also reserve the right, if, in our sole discretion, we determine that your use of the Platform or its services is un breach of these Terms and Conditions or of any applicable law or regulation, to terminate your use of the Platform and its services or delete your account and any or all of your content, without warning or prior notice. Suppose we terminate or suspend your account for any reason set out under this section. In that case, you are prohibited from registering and creating a new account under your name, or a false identity, or the expression of a third party. In addition to terminating or suspending your account, Callphone Limited reserves the right to take appropriate legal actions, including without limitation pursuing civil, criminal, and injunctive redress.</p>
                <p>
                Callphone reserves the right to, at its sole discretion, terminate your access to this Platform and the related Service or any part thereof at any time, for any reason and without notice.
                
                    <br />
                    <br />
                    Callphone shall have the right to terminate or suspend your account for violating these Terms.
                    <br />
                    <br />
                    If you register with us, you may terminate the Service at any time by issuing a prior notice to us. Once this is done, you will no longer be bound by the provisions of this Terms.
                </p>

                <h5>
                    GENERAL PROVISIONS
                </h5>

                <p>
                <strong>Language: </strong> All correspondence made under this Agreement shall be in English.
<br/>
<br/>
<strong>Governing Law & Jurisdiction: </strong> The Terms and Conditions of this Platform will be governed by and construed under the laws of the country or state in which Callphone Limited operates. You hereby unconditionally submit to the non exclusive jurisdiction of the courts located in Abuja, Nigeria for the resolution of any disputes.
<br/>
<br/>
<strong>Preservation of Immunities: </strong> Nothing herein shall constitute a limitation upon the privileges and immunities of Callphone Limited, which are specifically reserved.

                    <strong>Assignment: </strong> Callphone shall be permitted to assign, transfer, and subcontract its rights and/or obligations under this Agreement without any prior notification or consent required. However, you shall not be permitted to assign, transfer, or subcontract any rights and/or obligations under these Terms. Furthermore, a person who is not a party to these Terms and Conditions shall have no right to enforce any provision contained therein.
                    <br />
                    <br />

                    <strong>Entire Agreement: </strong> These Terms, disclaimers and any other agreement relating to the use of this Platform constitutes the entire agreement and shall supersede any other agreement in relation to the use of the Platform.
                    <br />
                    <br />
                    <strong>Separate Agreements: </strong> You may have other legal agreements with us. Those agreements are separate from these Terms. These Terms are not intended to alter, amend, revise or replace the terms of the other agreement(s).
                    <br />
                    <br />
                    <strong>Applicable law: </strong> These Terms may be governed and construed in accordance with the Laws of the Federal Republic of Nigeria.
                    <br />
                    <br />
                    <strong>Variation: </strong> Callphone reverses the right to revise these Terms at any time as it sees fit. By using Callphone, you are expected to review such Terms regularly to ensure you comprehend all the Terms and Conditions regarding the use of this Platform.
                    <br />
                    <br />
                    <strong>Waiver: </strong> Failure to exercise any right in these Terms shall not operate as a waiver. The right or remedies herein provided are cumulative and not exclusive of any right or remedies provided by law.
                    <br />
                    <br />
                    <strong>Severability: </strong> Supposed any Term or Condition is proven to be unenforceable or void under any applicable law. In that case, such shall not render the entirety of these Terms and Conditions unenforceable or invalid. As a result, any such provision shall be deleted without affecting the remaining provisions herein. The provisions of these Terms and Conditions that are unlawful, void, or unenforceable are deemed severable from these Terms and Conditions and do not affect any remaining provisions validity and enforceability.
                </p>

                <h5>INDEMNIFICATION</h5>
                <p>As a condition for the use of this Platform, the User agrees to indemnify Callphone Limited and its affiliates to the fullest extent, from and against all actions, claims, liabilities, losses, damages, costs, demands, and expenses (including reasonable attorney’s fees) arising out of the user’s use of this Platform, including without limitation, any claim related to the breach of any of the provisions of these Terms and Conditions. If dissatisfied with any or all of the content on this Platform or any or all of its Terms and Conditions, the user may discontinue using this Platform.</p>
                <p>You hereby agree to indemnify Callphone, its employees, agents and third parties from and against all liabilities, cost, demands, cause of action, damages and expenses (including reasonable attorney's fees) arising out of your unlawful use of the Platform, your violation of any rights of a third party and your violation of applicable laws, rules or regulation.</p>

                <h5>
                    CONTACT US
                </h5>
                <p>
                To resolve any complaint or clarification regarding the use of this Platform or its services or receive information concerning that, please contact us at <a href="mailto:info@callphoneng.com" style={{textDecoration:"underline", color: "blue"}}>info@callphoneng.com</a>
                </p>
                <p>
                    Full name of legal entity: Callphone Limited
                </p>
                <p>
                    <a href="mailto:info@callphoneng.com"  style={{textDecoration:"underline", color: "blue"}}>
                        Email address: info@callphoneng.com
                    </a>
                </p>
                <p>
                    Postal address:  Plot 1132, Cadastral Zone, Katampe District, Opposite Bon Hotels Grand Towers, Abuja.
                </p>
                <p>
                    Telephone number:  <a href="tel:+2349038864341"  style={{textDecoration:"underline", color: "blue"}}>(+234)9038864341</a>
                </p>




            </div>

            <div style={{height: "228px"}}></div>

            {/* Terms and Conditions */}
        </div>
    )
}


export default Privacy
