import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';


import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './css/faqs.css';


import Testimonial from '../../components/testimonial/testimonial'
import Nav from '../../components/nav/nav';
import Footer from '../../components/footer/footer';


const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
    }
  }));



function Faqs(props) {


    return (
        <div className="box-management">

            <div className="top-bg">
                <div className="container">
                    <Nav />
                    <div style={{ height: 137 }}/>

                    <div className="faqs-head">
                        <div>
                            <div className="faqs-title">
                                #AskUsAnything
                            </div>

                            <div style={{ height: 30 }}/>

                            <div className="faqs-msg">
                                We answered the most common questions from our users here!
                            </div>
                        </div>

                        <div className="faqs-hand">
                            <img src={require('../../components/asset/faqs-hand.svg').default} alt="" style={{maxWidth:566}}/>
                        </div>
                    </div>
                </div>

            </div>

            <div className="container body-flow">


                <div style={{ height: 144 }}/>



                {/* Faqs Start */}



                <Accordion>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className="acc-heading">HOW TO REGISTER?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{display: 'flex', flexDirection: "column"}}>
                                <Typography className="accordion-body">
                                        For Airvend Web
                                </Typography>
                                <Typography className="accordion-body">
                                    <ul>
                                        <li>
                                            Scroll down to Create Account
                                       </li>
                                        <li>
                                            Input your phone number into the Enter Phone Number section
                                        </li>
                                        <li>
                                            Click on Get free account
                                        </li>
                                        <li>
                                            Fill in the subsequent required information and submit
                                        </li>
                                        <li>
                                            You are good to go.
                                        </li>
                                    </ul>
                                </Typography>
                                <Typography className="accordion-body">
                                        Was this answer helpful? Have more questions? You can email us: support@airvend.ng
                                </Typography>
                                <Typography className="accordion-body">
                                        For Airvend App
                                </Typography>

                                <Typography className="accordion-body">
                                    <ul>
                                        <li>
                                            Scroll down to Create Account
                                       </li>
                                        <li>
                                            Input your phone number into the Enter Phone Number section
                                       </li>
                                            Click on Get free account
                                        <li>
                                            Fill in the subsequent required information and submit
                                        </li>
                                        <li>
                                            You are good to go.
                                        </li>
                                    </ul>
                                </Typography>
                                <Typography className="accordion-body">
                                    Was this answer helpful? Have more questions? You can email us: support@airvend.ng
                                </Typography>



                                <Typography className="accordion-body">
                                    For Airvend iOS
                                </Typography>

                                <Typography className="accordion-body">
                                    <ul>
                                        <li>
                                            Scroll down to Create Account
                                       </li>
                                        <li>
                                            Input your phone number into the Enter Phone Number section
                                       </li>
                                            Click on Get free account
                                        <li>
                                            Fill in the subsequent required information and submit
                                        </li>
                                        <li>
                                            You are good to go.
                                        </li>
                                    </ul>
                                </Typography>
                                <Typography className="accordion-body">
                                    Was this answer helpful? Have more questions? You can email us: support@airvend.ng
                                </Typography>


                            </div>
                        </AccordionDetails>
                    </Accordion>


                <div style={{height: "24px"}}></div>



                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className="acc-heading">How to Fund an Airvend Wallet</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{display: 'flex', flexDirection: "column"}}>
                            <Typography className="accordion-body">
                                    You can only fund your Airvend Wallet with your bank’s debit card; this process is powered by Flutterwave and guarantees the safety and security of your cards.
                            </Typography>
                            <Typography className="accordion-body">
                                <ul>
                                    <li>
                                        We accept all MasterCard, Visa and Verve cards, foreign cards like the American Express and Discover cards are also accepted.
                                    </li>
                                    <li>
                                        Typically, it takes a little less than 3minutes for funds to reflect on your wallet and any funding issue is solved within 24hrs of a business day.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography className="accordion-body">
                                To  fund your wallet:
                            </Typography>

                            <Typography className="accordion-body">
                                <ul>
                                    <li>
                                        Sign Up or Login to the Airvend app
                                    </li>
                                    <li>
                                        Tap on the add funds icon on the app’s dashboard
                                    </li>
                                        Enter the Deposit amount you want to fund with. There’s a minimum of 200 Naira and $5 for international cards
                                    <li>
                                        Tap on pay with new card and follow the on-screen instructions.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography className="accordion-body">
                                Once the process is complete, deposited funds would reflect on your airvend Wallet.
                            </Typography>



                            <Typography className="accordion-body">
                                Was this  helpful? Have more questions? You can email us: support@airvend.ng.
                            </Typography>




                        </div>
                    </AccordionDetails>
                </Accordion>


                <div style={{height: "24px"}}></div>




                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className="acc-heading">What services do you offer?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography className="accordion-body">
                        Having created an airvend account and funded your Airvend wallet, you can ultimately begin to;
                    </Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        <div style={{display: 'flex', flexDirection: "column"}}>
                            <Typography className="accordion-body">
                                <ul>
                                    <li>
                                        Send money to another Airvend account, any other e-wallet and any bank
                                    </li>
                                    <li>
                                        Receive money from another Airvend account, any other e-wallet and any bank
                                    </li>
                                    <li>
                                        Save money
                                    </li>
                                    <li>
                                        Directly pay for Electricity bills
                                    </li>
                                    <li>
                                        Directly pay for TV decoder subscriptions
                                    </li>
                                    <li>
                                            Directly purchase data and Airtime from network  providers in Nigeria
                                    </li>
                                    <li>
                                        Make payments for JAMB and WAEC
                                    </li>
                                </ul>
                            </Typography>
                            <Typography className="accordion-body">
                                Was this answer helpful? Have more questions? You can email us: support@airvend.ng
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <div style={{height: "24px"}}></div>



                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className="acc-heading">How to pay for Electricity on Airvend?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography className="accordion-body">
                        You can purchase Power on the Airvend App in 3 easy steps
                    </Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        <div style={{display: 'flex', flexDirection: "column"}}>
                            <Typography className="accordion-body">
                                <ul>
                                    <li>
                                        Sign Up or Login to the Airvend App
                                    </li>
                                    <li>
                                        On the app’s  dashboard tap on the power icon
                                    </li>
                                    <li>
                                        Select the Distribution company you intend to pay for (e.g IKEDC)
                                    </li>
                                    <li>
                                        Select account type and Input meter number
                                    </li>
                                    <li>
                                        Proceed to payment( You can either pay from your Airvend Wallet or with Saved card)
                                    </li>
                                    <li>
                                        Voila! Recharge pin is sent.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography className="accordion-body">
                                Was this answer helpful? Have more questions? You can email us: support@airvend.ng.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <div style={{height: "24px"}}></div>




                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className="acc-heading">How to pay for DSTV or GoTV on Airvend?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography className="accordion-body">
                        You can pay for Cable TV on the Airvend App in 3 easy steps
                    </Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        <div style={{display: 'flex', flexDirection: "column"}}>
                            <Typography className="accordion-body">
                                <ul>
                                    <li>
                                        Sign Up or Login to the Airvend App
                                    </li>
                                    <li>
                                        On the app’s dashboard tap on the  Cable TV icon.
                                    </li>
                                    <li>
                                        Select service provider( DSTV, OR GOTV)
                                    </li>
                                    <li>
                                        Select Bouquet  and Input decoder number
                                    </li>
                                    <li>
                                        Proceed to payment( You can either pay from your Airvend Wallet or with Saved card)
                                    </li>
                                </ul>
                            </Typography>
                            <Typography className="accordion-body">
                                Seamless right?
                            </Typography>
                            <Typography className="accordion-body">
                                Was this answer helpful? Have more questions? You can email us: support@airvend.ng.
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <div style={{height: "24px"}}></div>



                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className="acc-heading">How to use an Airvend Virtual Dollar Card</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography className="accordion-body">
                            The Airvend Virtual Dollar Card is a non-physical card for making payments online in dollars.
                        You can create your virtual card on any of the airvend platforms; either on the Mobile App or Web App.
                        <br />
                    </Typography>

                    <Typography className="accordion-body">
                        To do this your airvend wallet should be funded with at least the naira equivalent of $10 so your virtual card is funded with dollars converted from the naira in your Airvend wallet at the current dollar rate.
                    </Typography>

                    <Typography className="accordion-body">
                        You can also convert back the money in your dollar card to naira by transferring it to your Airvend Wallet.
                    </Typography>

                    <Typography className="accordion-body">
                        To get the Card:
                    </Typography>


                    </AccordionDetails>
                    <AccordionDetails>
                        <div style={{display: 'flex', flexDirection: "column"}}>
                            <Typography className="accordion-body">
                                <ul>
                                    <li>
                                            Login or sign up on the Airvend platform
                                    </li>
                                    <li>
                                        Click on Dollar cards
                                    </li>
                                    <li>
                                        Complete the funding process.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography className="accordion-body">
                                The virtual dollar card comes with regular card details (your card name, card number, expiry date and the three-digit CVV code) saved on your app. You can retrieve these details whenever you need them.
                            </Typography>
                            <Typography className="accordion-body">
                                Was this answer helpful? Have more questions? You can email us: support@airvend.ng
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <div style={{height: "24px"}}></div>



                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className="acc-heading">How to use an Airvend Virtual Card with a Nigerian Paypal account?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography className="accordion-body">
                        As an alternative, You can add your airvend virtual dollar card to Paypal and use your paypal on the platforms.
                    </Typography>

                    </AccordionDetails>
                    <AccordionDetails>
                        <div style={{display: 'flex', flexDirection: "column"}}>
                            <Typography className="accordion-body">
                                <ul>
                                    <li>
                                        To add your card to PayPal (if you're doing this from Nigeria), simply go to the account settings option on PayPal.com. Please note that the billing address must be the same as the address stated below:
                                    </li>
                                </ul>
                            </Typography>
                            <Typography className="accordion-body">
                                    Address Line 1 : 333
                                    <br/>Address Line 2 : Fremont Street
                                    <br/>City: San Francisco California
                                    <br/>ZIP CODE - do no input any information
                                    <br/>State: Lagos
                                    <br/>Country: Nigeria
                            </Typography>


                            <Typography className="accordion-body">
                                <ul>
                                    <li>
                                            Alternatively, if you have a US PayPal account, kindly add the US billing address below;
                                    </li>
                                </ul>
                            </Typography>


                            <Typography className="accordion-body">
                                Street 1: 333 Fremont Street
                                <br/>Street 2: San Francisco
                                <br/>City: San Francisco
                                <br/>State: CA - California
                                <br/>Zip Code: 94105
                                <br/>Once the billing address has been updated, kindly proceed to make use of Paypal on the platforms.
                            </Typography>

                            <Typography className="accordion-body">
                                Was this answer helpful? Have more questions? You can email us: support@airvend.ng
                            </Typography>



                        </div>
                    </AccordionDetails>
                </Accordion>

                <div style={{height: "24px"}}></div>


                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className="acc-heading">Want to get the Airvend Mobile App?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography className="accordion-body">
                        Airvend Mobile App is available on Google Play Store, App Store (iOS) and can also be accessed from our web @ airvend.ng
                    </Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                    <Typography className="accordion-body">
                        Was this answer helpful? Have more questions? You can email us: support@airvend.ng
                    </Typography>
                    </AccordionDetails>

                </Accordion>

                <div style={{height: "24px"}}></div>



                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className="acc-heading">How do I fund my Wallet?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{display: 'flex', flexDirection: "column"}}>
                            <Typography className="accordion-body">
                                You can only fund your Airvend Wallet with your bank’s debit card; this process is powered by Payant Technologies and guarantees the safety and security of your cards.
                                To  fund your wallet:
                            </Typography>
                            <Typography className="accordion-body">
                                <ul>
                                    <li>
                                        Sign Up or Login to the Airvend app
                                    </li>
                                    <li>
                                        Tap on the add funds icon on the app’s dashboard
                                    </li>
                                    <li>
                                        Enter the Deposit amount you want to fund with...
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <div style={{height: "24px"}}></div>




                {/* Faqs End */}

                <div style={{height: "24px"}}></div>






                <Footer removeAbsolue={true}/>

            </div>


        </div>
    );
}

export default Faqs
