// @ts-nocheck
import React from "react";
import "./header.css";
import logo from "../../../components/asset/airvendlogo.svg";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  return (
    <header className="referral_header_container">
      <nav className="referral_header_nav_container">
        <Link to="/">
          <img src={logo} alt="Airvend" />
        </Link>
        <ul className="referral_nav_list_container">
          <li className="referral_nav_list_item">
            <a
              href="https://documenter.getpostman.com/view/6349852/SVSHrpfv"
              target="_blank"
            >
              API Doc
            </a>
          </li>
          {location.pathname === "/referral-faqs" ? (
            <Link to="/referral" className="referral_nav_list_item">
              Referral
            </Link>
          ) : null}

          <Link to="/referral-faqs" className="referral_nav_list_item">
            FAQs
          </Link>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
