'use strict';

import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';

import history from '../config/history';

import routes from './routes';

const AppRoute = ({ Layout, Component, path }, ...rest) => (
    <Route {...rest} path={path} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )} />
)


const App = () => (
        <Router history={history}>
            <Switch>
                {routes.map((e, i) => <AppRoute key={i} exact path={e.route} Layout={e.layout} Component={e.component} />)}
            </Switch>
        </Router>
)

export default App;
