import AuthLayout from "../pages/auth-layout/auth-layout";
import Faqs from "../pages/faqs/faqs";
import HomePage from "../pages/home-page/home-page";

import LandingPage from "../pages/landing-page/landing-page";
import Legals from "../pages/legals/legals";
import Referral from "../pages/referral/Referral";
import ReferralFaq from "../pages/referral/ReferralFaq";

const _routes = [
  {
    component: HomePage,
    route: "/",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: HomePage,
    route: "/landing",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: HomePage,
    route: "/home",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: Faqs,
    route: "/faqs",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: Legals,
    route: "/legals/:page",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: Legals,
    route: "/legals",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: Referral,
    route: "/referral",
    layout: AuthLayout,
    protected: false,
  },
  {
    component: ReferralFaq,
    route: "/referral-faqs",
    layout: AuthLayout,
    protected: false,
  },
];

export default _routes;
