import React from 'react'
import { Link } from 'react-router-dom';
import Loader from '../loader/loader';
import './css/button.css'

interface Props {
    color: string,
    btn_name: string,
    icon?: JSX.Element,
    id?: string,
    paddingInfo?: string,
    fontStyle?: string,
    onClick?: any,
    loading?: boolean,
    disabled?: boolean,
    path?: string
}

export default function Button(props: Props) {
    const { onClick, loading, path } = props;
    const handleClick = () => onClick();

    return (
        <div>
            {path ? <BtnLink {...props} /> : <BtnMain {...props} />}
        </div>
    )
}

function BtnLink(props: any) {
    const { onClick, loading, path } = props;
    const handleClick = () => onClick();
    return (
        <Link
            to={path}
            id={props.id}
            // onClick={!loading ? handleClick : () => { }}
            className={`${props.color} btn-custom btn-link btn-round-corner 
                ${props.fontStyle} ${props.paddingInfo ? props.paddingInfo : "py-16"} 
                ${loading ? 'btn-disabled' : ''} ${props.icon ? 'btn-icon' : ''}`}>
            <div style={{ paddingRight: 15 }}>{props.icon}</div>
            {props.btn_name}
            {loading && <Loader type="btn" />}
        </Link>
    )
}

function BtnMain(props: any) {
    const { onClick, loading, disabled } = props;
    const handleClick = () => onClick();
    return (
        <div
            id={props.id}
            onClick={(!loading && !disabled) ? handleClick : () => { }}
            className={`${props.color} btn-custom btn-round-corner 
                ${props.fontStyle} ${props.paddingInfo ? props.paddingInfo : "py-16"} 
                ${(loading || disabled) ? 'btn-disabled' : ''} ${props.icon ? 'btn-icon' : ''}`}>
            <div style={{ paddingRight: 15 }}>{props.icon}</div>
            {props.btn_name}
            {loading && <Loader type="btn" />}
        </div>
    )
}
