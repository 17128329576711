import React, { useState } from 'react'
import './css/nav.css'

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '../../components/button/button';
import DropdownContent from '../../components/dropdown-content/dropdown-content';




interface Props {
}

function Nav(props) {

    const [open, setOpen] = useState(false);
    const [showMenus, setShowMenus] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
      };

      const handleClickAway = () => {
        setOpen(false);
      };


    return (


                    <nav className="navBar">

                        <div
                            className="d-block d-md-none d-lg-none"
                        >
                            <span
                                onClick={() => {
                                            setShowMenus(prev => !prev)
                                        }
                                    }
                                style={{cursor: "pointer", display: 'flex', alignItems: 'center'}}
                            >
                                    <div className="logo-area-login">
                                        <img src={require("../../components/asset/airvendlogo.svg").default} alt="" className="login-logo" />
                                    </div>
                                {/* <ExpandMoreIcon /> */}
                            </span>
                        </div>



                        <div className={showMenus ? "menu-block" : "menu-hidden"}>
                            <ul className="left-nav">




                                <li>
                                    <a className="d-none d-md-block d-lg-block" href="/">
                                        <div className="logo-area-login">
                                            <img src={require("../../components/asset/airvendlogo.svg").default} alt="" className="login-logo" />
                                        </div>
                                    </a>
                                </li>



                            </ul>
                        </div>


                        <div>
                            <ul className="right-nav">

                                <li id="noHover" style={{position: "relative"}}>

                                    <ClickAwayListener onClickAway={handleClickAway}>
                                        <div style={{position: "relative"}}>
                                            <span onClick={handleClick} style={{cursor: "pointer", display: 'flex', alignItems: 'center'}}>
                                                Learn <ExpandMoreIcon />
                                            </span>
                                            {open ? (
                                            <div className="dropdown-c">
                                                <DropdownContent
                                                    icon={
                                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect width="28" height="28" rx="5.25" fill="#0F9AF0" fill-opacity="0.1"/>
                                                                <path d="M14 18.25L13.0625 20.125L14 22C15.0355 22 15.875 21.1605 15.875 20.125C15.875 19.0895 15.0355 18.25 14 18.25Z" fill="#0A77E8"/>
                                                                <path d="M12.125 20.125C12.125 21.1605 12.9645 22 14 22V18.25C12.9645 18.25 12.125 19.0895 12.125 20.125Z" fill="#0F9AF0"/>
                                                                <path d="M14 6L12.125 7.875L14 9.75C14.6203 9.75 15.125 10.2547 15.125 10.875C15.125 11.2056 14.9799 11.5184 14.7269 11.7335L14 12.3514L12.75 14.832L14 17.3125H15.875V15.6794L17.1555 14.5908C18.2483 13.6622 18.875 12.3079 18.875 10.875C18.875 8.18691 16.6881 6 14 6V6Z" fill="#0A77E8"/>
                                                                <path d="M12.125 13.9456V17.3125H14V12.3514L12.125 13.9456Z" fill="#0F9AF0"/>
                                                                <path d="M9.125 10.875V11.3438H12.875V10.875C12.875 10.2547 13.3797 9.75 14 9.75V6C11.3119 6 9.125 8.18691 9.125 10.875Z" fill="#0F9AF0"/>
                                                            </svg>
                                                    }
                                                    heading="FAQs"
                                                    body="Frequently asked questions from other users."
                                                    onClick={() => {
                                                        window.location.href = "/faqs"
                                                    }}
                                                />
                                                <DropdownContent
                                                    icon={
                                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect width="28" height="28" rx="5.25" fill="#FF5CF0" fill-opacity="0.1"/>
                                                                <path d="M19.1805 19.5421L18.7634 19.9593L16.7094 17.9053C16.1996 17.3954 15.8521 16.746 15.7108 16.039L15.3789 14.3789L17.039 14.7107C17.095 14.7219 17.1505 14.7349 17.2057 14.7486L17.6443 17.1383C17.7856 17.8454 18.1331 18.4947 18.643 19.0046L19.1805 19.5421ZM9.34375 17.4844V9.51562C9.34375 7.89759 10.6554 6.58594 12.2734 6.58594L18.4829 6.56312L18.4844 6.55664L10.5156 6.58594C8.89759 6.58594 7.58594 7.89759 7.58594 9.51562V17.4844C7.58594 19.1024 8.89759 20.4141 10.5156 20.4141L12.0408 20.4049C10.5315 20.2863 9.34375 19.0241 9.34375 17.4844Z" fill="#CEE1F2"/>
                                                                <path d="M16.082 21H10.5156C8.57711 21 7 19.4229 7 17.4844V9.51562C7 7.57711 8.57711 6 10.5156 6H18.4844C20.4229 6 22 7.57711 22 9.51562V15.126C22 15.4496 21.7377 15.7119 21.4141 15.7119C21.0904 15.7119 20.8281 15.4496 20.8281 15.126V9.51562C20.8281 8.22328 19.7767 7.17188 18.4844 7.17188H10.5156C9.22328 7.17188 8.17188 8.22328 8.17188 9.51562V17.4844C8.17188 18.7767 9.22328 19.8281 10.5156 19.8281H16.082C16.4056 19.8281 16.668 20.0904 16.668 20.4141C16.668 20.7377 16.4056 21 16.082 21ZM21.3736 20.3736C22.2075 19.5397 22.2075 18.1829 21.3736 17.3491L19.3196 15.2951C18.726 14.7014 17.977 14.3007 17.1538 14.1362L15.4938 13.8043C15.3017 13.7659 15.1031 13.8261 14.9646 13.9646C14.8261 14.1031 14.766 14.3017 14.8043 14.4938L15.1362 16.1539C15.3007 16.9771 15.7015 17.726 16.2951 18.3197L18.3491 20.3736C18.753 20.7775 19.2901 21 19.8613 21C20.4326 21 20.9697 20.7775 21.3736 20.3736ZM16.9241 15.2853C17.5197 15.4043 18.0615 15.6942 18.491 16.1237L20.5449 18.1777C20.9219 18.5546 20.9219 19.168 20.5449 19.5449C20.3624 19.7275 20.1196 19.8281 19.8613 19.8281C19.6031 19.8281 19.3603 19.7275 19.1777 19.5449L17.1237 17.491C16.6943 17.0615 16.4044 16.5197 16.2853 15.9242L16.1257 15.1257L16.9241 15.2853Z" fill="#1428BF"/>
                                                                <path d="M18.4844 10.2773H13.123C12.7994 10.2773 12.5371 10.015 12.5371 9.69141C12.5371 9.36779 12.7994 9.10547 13.123 9.10547H18.4844C18.808 9.10547 19.0703 9.36779 19.0703 9.69141C19.0703 10.015 18.808 10.2773 18.4844 10.2773ZM19.0703 12.0352C19.0703 11.7115 18.808 11.4492 18.4844 11.4492H10.5156C10.192 11.4492 9.92969 11.7115 9.92969 12.0352C9.92969 12.3588 10.192 12.6211 10.5156 12.6211H18.4844C18.808 12.6211 19.0703 12.3588 19.0703 12.0352ZM13.2109 14.3789C13.2109 14.0553 12.9486 13.793 12.625 13.793H10.5156C10.192 13.793 9.92969 14.0553 9.92969 14.3789C9.92969 14.7025 10.192 14.9648 10.5156 14.9648H12.625C12.9486 14.9648 13.2109 14.7025 13.2109 14.3789ZM9.92969 9.63281C9.92969 10.0373 10.2576 10.3652 10.6621 10.3652C11.0666 10.3652 11.3945 10.0373 11.3945 9.63281C11.3945 9.22831 11.0666 8.90039 10.6621 8.90039C10.2576 8.90039 9.92969 9.22831 9.92969 9.63281Z" fill="#FF5CF0"/>
                                                            </svg>
                                                    }
                                                    heading="API Docs"
                                                    body="Build payment products with our documentation."
                                                    onClick={() => window.location.href = "https://documenter.getpostman.com/view/6349852/SVSHrpfv"}
                                                />
                                            </div>
                                            ) : null}
                                        </div>
                                    </ClickAwayListener>
                                </li>


                                <li>
                                    <a href="https://app.airvend.ng">
                                        Login
                                    </a>
                                </li>
                                <li>
                                    <div>
                                        <Button
                                            btn_name="Create Account"
                                            color="btn-black"
                                            onClick={() => {
                                                window.location.href = "https://app.airvend.ng/register"
                                            }}
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </nav>


    );
}

export default Nav
