import React from "react";
import "./google.css";

const GoogleStore = () => {
  return (
    <button
      className="google_store_btn"
      onClick={() =>
        (window.location.href =
          "https://play.google.com/store/apps/details?id=com.callphoneng.airvend")
      }
    >
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.645 11L14 13.445L3 20L11.645 11ZM0.857 0L11 9.998L0.857 20C0.344 19.734 0 19.25 0 18.62V1.38C0 0.75 0.344 0.265 0.857 0ZM15.361 7L17.477 8.321C18.167 8.875 18.167 10.123 17.52 10.677L15.361 12L13 9.5L15.361 7ZM3 0L14 6.555L11.645 9L3 0Z"
          fill="white"
        />
      </svg>
      <div className="">
        <div className="google_play_btn_small_text">Get in on</div>
        <div className="google_play_btn_large_text">Google Play</div>
      </div>
    </button>
  );
};

export default GoogleStore;
