import React, { useEffect, useState } from "react";
import "../styles/layout/referral-layout.css";
import carousel_one from "../components/asset/____.svg";
import carousel_two from "../components/asset/1____.svg";

const ReferralLayout = ({ children }: { children: React.ReactNode }) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((count) => ++count);
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="app_referral_layout">
      <div
        className="carousel_box_container move_left_right"
        style={{ display: `${count % 2 ? "block" : "none"}` }}
      >
        <img src={carousel_one} className="carousel_image_one" />
      </div>
      <div
        className="carousel_box_container move_right_left"
        style={{ display: `${count % 2 ? "none" : "block"}` }}
      >
        <img src={carousel_two} className="carousel_image_two" />
      </div>
      {children}
    </div>
  );
};

export default ReferralLayout;
