import React from "react";
import ReferralLayout from "../../layout/ReferralLayout";
import Header from "../../components/referral/header/Header";
import "../../styles/pages/referral.css";
import GoogleStore from "../../components/button/store/google/GoogleStore";
import AppleStore from "../../components/button/store/apple/AppleStore";
import Footer from "../../components/footerV2/Footer";

const Referral = () => {
  return (
    <ReferralLayout>
      <main className="referral_content">
        <Header />
        <div className="referral_content_area_wrapper">
          <h1 className="referral_header_text">#SeamlessPayments</h1>
          <h6 className="referral_sub_header_text">
            A seamless bill payment service for individuals and businesses.
          </h6>
          <div className="app_store_btn_container">
            <GoogleStore />
            <AppleStore />
          </div>
          <Footer />
        </div>
      </main>
    </ReferralLayout>
  );
};

export default Referral;
